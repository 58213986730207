import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {KnowledgeBaseService} from 'src/app/modules/knowledge-base/knowledge-base.service';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {GetAllAnswers} from 'src/app/store/knowledge-base/kb-items.actions';
import {firstValueFrom} from 'rxjs';
import {errorHandler} from 'src/app/shared/helpers/error-handler';
import {KnowledgeBaseItem} from 'src/app/interfaces/general/knowledge-base/knowledge-base-item.interface';

export interface IKbItemsState {
    data: KnowledgeBaseItem[];
    hasValue: boolean;
    loading: boolean;
    error: any;
}

@State<IKbItemsState>({
    name: 'kbItems',
    defaults: {
        data: [],
        hasValue: false,
        loading: false,
        error: null,
    },
})
@Injectable()
export class KnowledgeBaseItemsState {
    constructor(
        private kbService: KnowledgeBaseService,
        private insights: AppInsightsService,
    ) {}

    @Selector()
    public static answers(state: IKbItemsState): KnowledgeBaseItem[] {
        return state.data;
    }

    @Selector()
    public static loading(state: IKbItemsState): boolean {
        return state.loading;
    }

    @Selector()
    public static error(state: IKbItemsState): any {
        return state.error;
    }

    @Action(GetAllAnswers)
    public async getAllAnswers(ctx: StateContext<IKbItemsState>): Promise<void> {
        ctx.patchState({loading: true, error: null});
        try {
            const kbItems: KnowledgeBaseItem[] = await firstValueFrom(this.kbService.getAll());
            ctx.patchState({
                data: kbItems,
                hasValue: !!kbItems?.length,
                loading: false,
                error: null,
            });
        } catch (error) {
            errorHandler({
                error,
                appInsightsSrv: this.insights,
                scope: 'KnowledgeBaseItemsState',
            });
            ctx.patchState({
                data: [],
                hasValue: false,
                loading: false,
                error: error.message ?? error,
            });
        }
    }
}
