import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {KnowledgeBaseItem} from 'src/app/interfaces/general/knowledge-base/knowledge-base-item.interface';

@Injectable({providedIn: 'root'})
export class KnowledgeBaseService {
    private http = inject(HttpClient);

    public getAll(): Observable<KnowledgeBaseItem[]> {
        return this.http
            .get<{knowledgeBase: KnowledgeBaseItem[]}>('/assets/knowledge-base/knowledge-base.json')
            .pipe(map(({knowledgeBase}) => knowledgeBase));
    }
}
